import Row from "../components/Row";
import { Typography } from "@mui/material";
import { imageNames } from "../images/imagesNames";
import Button from "@mui/material/Button";
import "./GalleryPage.css";
import Navbar from "../components/Navbar";
import Vidrow from "../components/Vidrow";
const GalleryPage = () => {
  return (
    <div className="whole" >
      <Navbar/>
      <Typography
        sx={{
          padding: "40px",
          textAlign: "center",
          fontFamily: "Cursive",
          fontSize: 65,
          paddingTop:"20px"
        }}
      >
        Our 1 Year
      </Typography>

      <Row imageNames={imageNames[0]} direction="left" />
      <Row imageNames={imageNames[1]} direction="right" />
      <Row imageNames={imageNames[2]} direction="left" />
      
      <Button size="large" className='buttons' variant='contained' color="primary" href='/Abouts'>
        Let's Continue Amor :)
        </Button>
    </div>
  );
};

export default GalleryPage;