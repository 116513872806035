import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, Paper } from '@mui/material';

const TextImageBlock = ({ title, message, orientation, image, children, vertical }) => {
    const theme = useTheme();
    const [showMessage, setShowMessage] = React.useState(false);

    
    return (
        <Box fontFamily={"cursive"} sx={{ marginTop: 10, marginBottom: 10 }}>
            <Paper elevation={16} sx={{ fontFamily:'cursive', display: 'flex', alignItems: 'center', flexDirection: `${vertical?'column':'row'}` }}>
                {orientation === "left1" &&
                    <CardMedia
                        component="img"
                        fontFamily="cursive"
                        sx={vertical?{width:'400px', paddingTop: '56px',fontFamily:"cursive"}:{width:'calc(100% - 600px)', paddingBottom: '80px', paddingLeft: '80px', paddingTop: '80px', paddingRight: '40px',fontFamily:"cursive"}}
                        image={"ross.jpg"
                        }
                        alt="Live from space album cover" height={"1000px"} //change to {alt}
                    />
                }
                {orientation === "left4" &&
                    <CardMedia
                        component="img"
                        sx={vertical?{width:'300px', paddingTop: '56px'}:{width:'calc(100% - 700px)', paddingBottom: '80px', paddingLeft: '80px', paddingTop: '80px', paddingRight: '40px'}}
                        image={"aboutus.jpg"
                        }
                        alt="Live from space album cover" height={"700px"} //change to {alt}
                    />
                }
                {orientation === "left" &&
                    <CardMedia
                        component="img"
                        sx={vertical?{width:'400px',paddingRight:"100px", paddingTop: '56px'}:{width:'500px',height:"10%", paddingBottom: '80px', paddingLeft: '80px', paddingTop: '80px', paddingRight: '40px'}}
                        image={"14.jpg"
                        }
                        alt="Live from space album cover" height={"1000px"} //change to {alt}
                    />
                }
                {orientation === "left3" &&
                    <CardMedia
                        component="img"
                        sx={vertical?{width:'600px', paddingTop: '56px'}:{width:'calc(100% - 100px)', paddingBottom: '80px', paddingLeft: '80px', paddingTop: '80px', paddingRight: '40px'}}
                        image={"3.jpg"
                        }
                        alt="Live from space album cover" height={"1000px"} //change to {alt}
                    />
                }
                
                <Box sx={{ padding: 5, justifyContent: 'center', display: 'flex', flexDirection: 'column',fontFamily:"cursive",width: "calc(100% - 100px)" }}>
                    <CardContent sx={{ flex: '0 0 auto', justifyContent: 'center' }}>
                        <Typography component="div" variant="h5">
                            {title}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" fontFamily={"cursive"} component="div">
                            {message}
                        </Typography>
                        {children && showMessage &&
                            children
                        }
                        {children && 
                            <Button
                             sx={{display:'flex', verticalAlign:'center'}}
                             onClick={()=>{setShowMessage(!showMessage)}}>{showMessage?'hide' : 'show'}</Button>}
                    </CardContent>
                </Box>

                {orientation === "right" &&

                    <CardMedia
                        component="img"
                        sx={{ width: `${vertical?'200px' : 'calc(100% - 200px)' }`, padding: '30px'}}
                        image="togeth.jpg"
                        alt="Live from space album cover" height={"800px"} //change to {props.alt}
                    />
                }
                 {orientation === "right1" &&

<CardMedia
    component="img"
    sx={{ width: `${vertical?'200px' : 'calc(50%)' }`, padding: '30px'}}
    image="may18.jpg"
    alt="Live from space album cover" //change to {props.alt}
/>
}
            </Paper>
        </Box>
    );
}

export default TextImageBlock;