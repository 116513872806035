import { ReactFloatingBalloons } from "react-floating-balloons";
import "./styles.css";
import { Button } from "@mui/material";


const Ballons = () => {
  const supportsTouch = "ontouchstart" in window || navigator.msMaxTouchPoints;

  return (
    <div className="Ballon">
        
      <h1>Happy Birthday Amor!</h1>
      {supportsTouch ? (
        <h2>Another Year With You Amor</h2>
      ) : (
        <h2>PS... you can pop these balloons</h2>
      )}
      <ReactFloatingBalloons
        count={5}
        msgText="HAPPPYYYY 24!!!!!"
        colors={["green"]}
        popVolumeLevel={0.1}
      />
      <ReactFloatingBalloons
        count={5}
        msgText="HAPPPYYYY BDAY"
        colors={["purple"]}
        popVolumeLevel={0.1}
      />
      <ReactFloatingBalloons
        count={5}
        msgText="PARTY TIME"
        colors={["blue"]}
        popVolumeLevel={0.1}
        
      />
      <ReactFloatingBalloons
        count={5}
        msgText="CONGRATSS!!"
        colors={["red"]}
        popVolumeLevel={0.1}
      />
      <div className="But" >
        <Button size="large" className='buttons' variant='contained' color="primary" href='/Birthday'>
            Happy B-Day Amor. Let's See Our Gallery :)
        </Button>
      </div>
      </div>
      
    
  );
}
export default Ballons;
