import Row from "../components/Row";
import { Typography } from "@mui/material";
import { Bday } from "../images/Bday";
import Button from "@mui/material/Button";
import "./GalleryPage.css";
import Navbar from "../components/Navbar";
import Vidrow from "../components/Vidrow";
import { ReactFloatingBalloons } from "react-floating-balloons";

const Birthday = () => {
  return (
    <div className="whole" >
      <Navbar/>
      <Typography
        sx={{
          padding: "40px",
          textAlign: "center",
          fontFamily: "Cursive",
          fontSize: 65,
          paddingTop:"20px"
        }}
      >
        Our Second Birthday Trip!
      </Typography>

      <Row imageNames={Bday[0]} direction="left" />
      <Row imageNames={Bday[1]} direction="right" />
      <Row imageNames={Bday[2]} direction="left" />
      <ReactFloatingBalloons
        count={5}
        msgText="CONGRATSS!!"
        colors={["red"]}
        popVolumeLevel={0.1}
      />
      <Button size="large" className='buttons' variant='contained' color="primary" href='/About'>
        Happy Birthday Amor I'm So Happy For You :)
        </Button>
    </div>
  );
};

export default Birthday;