import { fontFamily, fontSize } from "@mui/system";
import "./end.css";
import React from "react"
import Navbar from "./Navbar";
export default function End (){
    return(
        <div className="top">
            <Navbar/>
            
        <div className="end">
            
            Coming Soon...
            </div>
            </div>



    );}
