import Block from "./TextImageBlocks";
import Box from '@mui/material/Box';
import { Button } from "@mui/material";
import { Helmet } from 'react-helmet'
import { Typography } from "@mui/material";
import Fade from 'react-reveal/Fade';
import Navbar from "./Navbar";
const Today = () => {
    const loremIpsum = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum"

    return (
        <div className="sec">
            <Navbar/>
        <Box className="sec" fontSize="100px" fontFamily={"cursive"} textAlign="center" justifyContent={"center"} color="whitesmoke" >
                
                What we will be doing today!!!!
            <Fade>
                <Block
                    title="You Will Find This At The End :)"
                    message={"This section will be quite short but trust me...today will be amazing. As for this section...you will see what we will be doing first later on ;)"}
                    orientation="left1"
                />
            </Fade>
            
            <Fade>
            <Block
                title="Pictures Round 2 :)"
                message={"YAYYYY we are gonna go to 2 places and after some research it works best...the setting is beautifulll and I figured some day we can make a scrapbook or even add to this website a year by year picture gallery. We will be going to a park and a closed restaurant that has a good setting and we are gonna take bomb pictures soooooooo let's gooooo." }
                orientation="right"

            />
            </Fade>
            

            <Fade>
            <Block
                title="Let's go ride go-karts :)"
                message={"As you can seeee let's go ride go-karts...remember when I called you and asked if you had gone...yeaa..I am bad at hiding things from you....welp...Anyways...these karts go up to 45 mph so I figured you should be fine...just good practice to drive. They also have pool I think and other activities so we can play games...so let's gooooooo"}
                orientation="left"

            />
            </Fade>

            

            <Fade>
            <Block
                title="Surprise.....ouuuuuu"
                message={"This is another surprise...I have been working on this for some time and even needed help...so I saved this one for last...save the best for last right?...P.S there is a very small hint within this picture that gives it away...try and find it ouuuuuu lol"}
                orientation="right1"

            />
            </Fade>
            <Button size="large" className='text' variant='contained' color="primary" href='/Game'>
        Let's Play A Game :)
        </Button>
        </Box>
        </div>
        
    );
}

export default Today;