import './App.css';
import ContactForm from './components/ContactForm';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import TextImageBlock from './components/TextImageBlock';
import AboutPage from './pages/AboutPage';
import { Games } from './components/Games';
import {
  BrowserRouter, Route,
  Link, Routes
} from 'react-router-dom';
import HomePageImages from './components/HomePageImages';
import HomePage from './pages/HomePage';
import GalleryPage from './pages/GalleryPage';
import Today from './components/Today';
import End from './components/End';
import Ballons from './pages/Ballons';
import Birthday from './pages/Birthday';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
            <Ballons/>
            }
          />
           <Route
            path="/Birthday"
            element={
              <Birthday />
            }
          />
          <Route
            path="/Gallery"
            element={
              <GalleryPage />
            }
          />
          <Route
            path="/About"
            element={
              <HomePageImages />
            }
          />
          <Route
            path="/Today"
            element={
              <Today />
            }
          />
          <Route
            path="/Game"
            element={
              <Games/>
            }
          />
          <Route
            path="/End"
            element={
              <End/>
            }
          />

          

          

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
