const imageNames = [
    [
      {
        img: "1.jpg",
        title: "Breakfast",
        id: 1,
      },
      {
        img: "2.jpg",
        title: "Burger",
        id: 2,
      },
      {
        img: "3.jpg",
        title: "Camera",
        id: 3,
      },
      {
        img: "4.jpg",
        title: "Coffee",
        id: 4,
      },
      {
        img: "5.jpg",
        title: "Hats",
        id: 5,
      },

      {
        img: "6.jpg",
        title: "Honey",
        author: "@arwinneil",
        id: 6,
      },
      {
        img: "7.jpg",
        title: "Basketball",
        id: 7,
      },
      {
        img: "8.jpg",
        title: "Fern",
        id: 8,
      },
      {
        img: "9.jpg",
        title: "Mushrooms",
        id: 9,
      },
      {
        img: "10.jpg",
        title: "Tomato basil",
        id: 10,
      },
      {
        img: "12.jpg",
        title: "Coffee",
        id: 4,
      },
      {
        img: "12.jpg",
        title: "Hats",
        id: 5,
      },

      {
        img: "13.jpg",
        title: "Honey",
        author: "@arwinneil",
        id: 6,
      },
      {
        img: "14.jpg",
        title: "Basketball",
        id: 7,
      },
      {
        img: "15.jpg",
        title: "Fern",
        id: 8,
      },
      {
        img: "18.jpg",
        title: "mp4",
        id: 9,
      }, {
        img: "19.jpg",
        title: "mp4",
        id: 4,
      },
      {
        img: "20.jpg",
        title: "Hats",
        id: 5,
      },

      {
        img: "21.jpg",
        title: "Honey",
        author: "@arwinneil",
        id: 6,
      },
      {
        img: "22.jpg",
        title: "Basketball",
        id: 7,
      }, {
        img: "24.jpg",
        title: "Breakfast",
        id: 1,
      },
      {
        img: "27.jpg",
        title: "Burger",
        id: 2,
      },
      {
        img: "28.jpg",
        title: "Camera",
        id: 3,
      },
      {
        img: "30.jpg",
        title: "Coffee",
        id: 4,
      },
      {
        img: "31.jpg",
        title: "Hats",
        id: 5,
      },

      {
        img: "38.jpg",
        title: "Honey",
        author: "@arwinneil",
        id: 6,
      },
      {
        img: "39.jpg",
        title: "Basketball",
        id: 7,
      },
      {
        img: "40.jpg",
        title: "Fern",
        id: 8,
      },
      {
        img: "41.jpg",
        title: "Mushrooms",
        id: 9,
      },
      {
        img: "42.jpg",
        title: "Tomato basil",
        id: 10,
      }
    ],
    [

      {
        img: "43.jpg",
        title: "Breakfast",
        id: 1,
      },
      {
        img: "44.jpg",
        title: "Burger",
        id: 2,
      },
      {
        img: "45.jpg",
        title: "Camera",
        id: 3,
      },
      {
        img: "46.jpg",
        title: "Coffee",
        id: 4,
      },
      {
        img: "47.jpg",
        title: "Hats",
        id: 5,
      },

      {
        img: "48.jpg",
        title: "Honey",
        author: "@arwinneil",
        id: 6,
      },
      {
        img: "49.jpg",
        title: "Basketball",
        id: 7,
      },
      {
        img: "50.jpg",
        title: "Fern",
        id: 8,
      },
      {
        img: "51.jpg",
        title: "Mushrooms",
        id: 9,
      },
      {
        img: "52.jpg",
        title: "Tomato basil",
        id: 10,
      },
      {
        img: "53.jpg",
        title: "Coffee",
        id: 4,
      },
      {
        img: "54.jpg",
        title: "Hats",
        id: 5,
      },

      {
        img: "55.jpg",
        title: "Honey",
        author: "@arwinneil",
        id: 6,
      },
      {
        img: "56.jpg",
        title: "Basketball",
        id: 7,
      },
      {
        img: "57.jpg",
        title: "Fern",
        id: 8,
      },
      {
        img: "58.jpg",
        title: "mp4",
        id: 9,
      }, {
        img: "59.jpg",
        title: "mp4",
        id: 4,
      },
      {
        img: "60.jpg",
        title: "Hats",
        id: 5,
      },

      {
        img: "61.jpg",
        title: "Honey",
        author: "@arwinneil",
        id: 6,
      },
      {
        img: "62.jpg",
        title: "Basketball",
        id: 7,
      }, {
        img: "63.jpg",
        title: "Breakfast",
        id: 1,
      },
      {
        img: "65.jpg",
        title: "Burger",
        id: 2,
      },
      {
        img: "66.jpg",
        title: "Camera",
        id: 3,
      },
      {
        img: "68.jpg",
        title: "Coffee",
        id: 4,
      },
      {
        img: "70.jpg",
        title: "Hats",
        id: 5,
      },

      {
        img: "71.jpg",
        title: "Honey",
        author: "@arwinneil",
        id: 6,
      },
      {
        img: "72.jpg",
        title: "Basketball",
        id: 7,
      },
      {
        img: "73.jpg",
        title: "Fern",
        id: 8,
      },
      {
        img: "74.jpg",
        title: "Mushrooms",
        id: 9,
      },
      {
        img: "75.jpg",
        title: "Tomato basil",
        id: 10,
      }
    ],
    [
      {
        img: "76.jpg",
        title: "Breakfast",
        id: 1,
      },
      {
        img: "77.jpg",
        title: "Burger",
        id: 2,
      },
      {
        img: "79.jpg",
        title: "Camera",
        id: 3,
      },
      {
        img: "80.jpg",
        title: "Coffee",
        id: 4,
      },
      {
        img: "82.jpg",
        title: "Hats",
        id: 5,
      },

      {
        img: "83.jpg",
        title: "Honey",
        author: "@arwinneil",
        id: 6,
      },
      {
        img: "84.jpg",
        title: "Basketball",
        id: 7,
      },
      {
        img: "85.jpg",
        title: "Fern",
        id: 8,
      },
      {
        img: "86.jpg",
        title: "Mushrooms",
        id: 9,
      },
      {
        img: "87.jpg",
        title: "Tomato basil",
        id: 10,
      },
      {
        img: "88.jpg",
        title: "Coffee",
        id: 4,
      },
      {
        img: "89.jpg",
        title: "Hats",
        id: 5,
      },

      {
        img: "90.jpg",
        title: "Honey",
        author: "@arwinneil",
        id: 6,
      },
      {
        img: "91.jpg",
        title: "Basketball",
        id: 7,
      },
      {
        img: "92.jpg",
        title: "Fern",
        id: 8,
      },
      {
        img: "93.jpg",
        title: "mp4",
        id: 9,
      }, {
        img: "94.jpg",
        title: "mp4",
        id: 4,
      },
      {
        img: "95.jpg",
        title: "Hats",
        id: 5,
      },

      {
        img: "96.jpg",
        title: "Honey",
        author: "@arwinneil",
        id: 6,
      },
      {
        img: "97.jpg",
        title: "Basketball",
        id: 7,
      }, {
        img: "98.jpg",
        title: "Breakfast",
        id: 1,
      },
      {
        img: "99.jpg",
        title: "Burger",
        id: 2,
      },
      {
        img: "100.jpg",
        title: "Camera",
        id: 3,
      },
      {
        img: "101.jpg",
        title: "Coffee",
        id: 4,
      },
      {
        img: "102.jpg",
        title: "Hats",
        id: 5,
      },

      {
        img: "103.jpg",
        title: "Honey",
        author: "@arwinneil",
        id: 6,
      },
      {
        img: "104.jpg",
        title: "Basketball",
        id: 7,
      },
      {
        img: "105.jpg",
        title: "Fern",
        id: 8,
      },
      {
        img: "106.jpg",
        title: "Mushrooms",
        id: 9,
      },
      {
        img: "107.jpg",
        title: "Tomato basil",
        id: 10,
      }
    ]
  ]
export { imageNames };