import react from "react";
import { useState } from "react";

import "./Navbar.css"
export default function Navbar() {
    const [click, setClick] = useState(false);
  
    return (
        <a className="navigation-bar" href="/">
            YESENIA AND HERNAN 
            
            <div classname="nav-menu" >
                <ul className="lists" >
                    
                    <li className="items" color="white" >
                        <a className="textitems" href="/Abouts">
                            About Us
                        </a>
                    </li>
                    <li className="items">
                        <a className="textitems" href="/Gallery">
                            Gallery
                        </a>
                    </li>
                    <li className="items">
                        <a className="textitems" href="/About">
                            Why You
                        </a>
                    </li>
                    <li className="items">
                        <a className="textitems" href="/Today">
                            1st Year
                        </a>
                    </li>
                    <li className="items">
                        <a className="textitems" href="/End">
                            2nd Year
                        </a>
                    </li>
                </ul>

            </div>
        </a>
    )


}