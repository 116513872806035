import "./Row.css";
import ReactPlayer from "react-player";
import { imageNames } from "../images/imagesNames";
function Row({ imageNames, direction }) {
  return (
    <div className="row">
      <div className={`row_posters ${direction}`}>
        
        {imageNames.map((item) => (
           
            <img
            key={item.id}
            className={"row_poster"}
            src={item.img}
            alt={(item+1).img}
          />

        ))}
      </div>
    </div>
  );
}

export default Row;
