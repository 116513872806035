import React, { Component } from 'react'
import ReactPlayer from 'react-player'

class Vidrow extends Component {
    render () {
        return (
        <div className='player-wrapper'>
            <ReactPlayer
            className='react-player fixed-bottom'
            url= './16.mp4'
            width="50px"
            height='50px'
            controls = {true}

            />
        </div>
        )
    }
}

export default Vidrow;