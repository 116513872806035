import React from 'react';
import ReactDOM from 'react-dom';
import { Button,Box } from "@mui/material";
import Wheel from './Wheel';

import './styles.css';

export class Games extends React.Component {
  constructor() {
    super();
    this.places = ['Purse (Of your choice)', 'Fragrances', 'Jewlery (JA,ORO,Pandora)', 'Sony Headphones', 'Clothes', 'Legos','buzz lightyear pop','One whole dollar','Shoes(Of my choice lol)','a kiss'];
  }
  
  render() {
    return (
      <div fontFamily="cursive" className="App">
        <h1 fontFamily="cursive">Choose Your Gift Amor...</h1>
        <Wheel items={this.places} />
        <Box fontFamily={"cursive"} paddingTop={"20px"}>
          <h3>I Love You </h3>
          <h3>To The Moon And Back</h3> 
          <h3>To Infinity And Beyond </h3>
          <h3>To The End Of The Universe</h3>
          <h3>Forever And Ever</h3>
          <h5>Let's Go Get Your Gift Amor :) </h5>
        <Button variant='contained' href="/End">Second Year Anniversary Amor...Let's Go..</Button>
        </Box>
      </div>
    );
  }
}
